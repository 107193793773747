import { nanoid } from "nanoid";

export class AuthoringKey {

    private constructor(id:string, version:number){
        this.id = id;
        this.version = version;
    }

    public id: string;
    public version: number;
    
    public static Create(){
        return new AuthoringKey(nanoid(), 1);
    }

    public CreateNewVersion(){
        return new AuthoringKey(this.id, this.version + 1)
    }

    public static Hydrate(id:string, version:number){
        return new AuthoringKey(id, version);
    }

    public static FromJsonObject(authoringKeyObject:any){
        return new AuthoringKey(authoringKeyObject.id, authoringKeyObject.version);
    }

    public ToPoco():any{
        return {
            id: this.id,
            version: this.version
        };
    }

    public ToString():string{
        return this.id + '_' + this.version;
    }

}
