
export enum EntityReviewStatus {
    Draft,
    Published,
    Superceeded
}

export function entityReviewStatusString(status: EntityReviewStatus):string{
    return EntityReviewStatus[status];
}
