import { Timestamp } from "@angular/fire/firestore";
import { nanoid } from "nanoid";
import { EntityReviewStatus } from "../enums/entityReviewStatus.enum";
import { AuthoringKey } from "../authoring-key";

export default class TournamentEventCampaign {
    uid: string;
    authoringKey: AuthoringKey;
    startDate: Date;
    endDate: Date;
    status: EntityReviewStatus;
    organisationId: string;
    posterPath: string;
    contactEmail: string;
    contactPhoneNumber: string;
    entryWebsite: string;
    seasonAgeGroupStartYear: number;
    seasonAgeGroupEndYear: number;


    private constructor(uid: string, authoringKey: AuthoringKey, startDate: Date, endDate: Date, status: EntityReviewStatus, organisationId: string, posterPath: string, contactEmail: string, contactPhoneNumber: string, entryWebsite: string, seasonAgeGroupStartYear: number, seasonAgeGroupEndYear: number) {
        this.uid = uid;
        this.authoringKey = authoringKey;
        this.startDate = startDate;
        this.endDate = endDate;
        this.status = status;
        this.organisationId = organisationId;
        this.posterPath = posterPath;
        this.contactEmail = contactEmail;
        this.contactPhoneNumber = contactPhoneNumber;
        this.entryWebsite = entryWebsite;
        this.seasonAgeGroupStartYear = seasonAgeGroupStartYear;
        this.seasonAgeGroupEndYear = seasonAgeGroupEndYear;
    }

    public static Create(startDate: Date, endDate: Date, contactEmail: string, contactPhoneNumber: string, entryWebsite: string, seasonAgeGroupStartYear: number, seasonAgeGroupEndYear: number, organisationId: string): TournamentEventCampaign {
        return new TournamentEventCampaign(nanoid(), AuthoringKey.Create(), startDate, endDate, EntityReviewStatus.Draft, organisationId, null, contactEmail, contactPhoneNumber, entryWebsite, seasonAgeGroupStartYear, seasonAgeGroupEndYear);
    }

    public Update(startDate: Date, endDate: Date, contactEmail: string, contactPhoneNumber: string, entryWebsite: string, seasonAgeGroupStartYear: number, seasonAgeGroupEndYear: number): void {
        this.startDate = startDate; 
        this.endDate = endDate;
        this.contactEmail = contactEmail;
        this.contactPhoneNumber = contactPhoneNumber;
        this.entryWebsite = entryWebsite; 
        this.seasonAgeGroupStartYear = seasonAgeGroupStartYear; 
        this.seasonAgeGroupEndYear = seasonAgeGroupEndYear;
    }

    public static Hydrate(uid: string, authoringKey: AuthoringKey, startDate: Date, endDate: Date, status: EntityReviewStatus, organisationId: string, posterPath: string, contactEmail: string, contactPhoneNumber: string, entryWebsite: string, seasonAgeGroupStartYear: number, seasonAgeGroupEndYear: number): TournamentEventCampaign {
        return new TournamentEventCampaign(uid, authoringKey, startDate, endDate, status, organisationId, posterPath, contactEmail, contactPhoneNumber, entryWebsite, seasonAgeGroupStartYear, seasonAgeGroupEndYear);
    }

    public ToPoco(): any {
        return {
            authoringKey: this.authoringKey.ToPoco(),
            startDate: Timestamp.fromDate(this.startDate),
            endDate: Timestamp.fromDate(this.endDate),
            status: this.status,
            organisationId: this.organisationId,
            posterPath: this.posterPath,
            contactEmail: this.contactEmail,
            contactPhoneNumber: this.contactPhoneNumber,
            entryWebsite: this.entryWebsite,
            seasonAgeGroup:  { startYear: this.seasonAgeGroupStartYear, endYear:  this.seasonAgeGroupEndYear }
        };
    }
}
