import { Dictionary, groupBy } from 'lodash';
import { Injectable, Signal, computed, signal } from '@angular/core';
import { collectionData, documentId, Firestore, and, collection, getDocs, getFirestore, query, where, updateDoc, orderBy } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { Organisation } from './models/organisation';
import { Observable, map } from 'rxjs';
import { nanoid } from 'nanoid';
import { Timestamp, doc, limit, setDoc } from 'firebase/firestore';
import { Auth, User, authState } from '@angular/fire/auth';
import { Time } from '@angular/common';
import { TournamentEvent } from './models/authoring/tournamentEvent.model';
import { Functions } from '@angular/fire/functions';
import { httpsCallable } from 'firebase/functions';
import TournamentEventCampaign from './models/authoring/tournamentEventCampaign.model';
import { Tournament } from './models/authoring/tournament.model';
import { CampaignReview, CampaignReviewStatus } from './models/review/campaignReview.model';
import { EntityReview } from './models/entity-review.model';
import { AuthoringKey } from './models/authoring-key';
import { Age } from './models/enums/age.enum';
import { deleteObject, getBlob, getStorage, ref, uploadBytes } from '@angular/fire/storage';
import { ReviewEntity } from './models/review/review-entity.model';
import { TournamentEventLocation } from './models/authoring/tournamentEventLocation.model';


@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(public afs: Firestore, // Inject Firestore service
    private authService: AuthService,
    private afAuth: Auth,
    private functions: Functions) {

    console.log("Org service constructed");

    authState(this.afAuth)
      .subscribe((user: User) => {
        if (user) {
          user.getIdTokenResult().then(token => {
            let orgClaim = token.claims['organisationId'] as string;
            if (orgClaim) {
              this.initializeOrganisation(orgClaim, user);
            }
          });
        }
        // this.UserOrganisations().subscribe(orgs => {
        //   if (orgs.length) {

        //     const orgClaimsCallable = httpsCallable(this.functions, "setOrganisationClaims");

        //     orgClaimsCallable({ organisationId: orgs[0].uid, email: user.email });
        //   }
        // })
      })
  }

  private _organisations = signal<Organisation[]>(null);
  organisations = this._organisations.asReadonly();

  private _organisation = signal<Organisation>(null);
  organisation = this._organisation.asReadonly();

  private _eventCampaigns = signal<TournamentEventCampaign[]>([]);
  eventCampaigns = this._eventCampaigns.asReadonly();

  latestEventCampaigns: Signal<TournamentEventCampaign[]> = computed(() => {

    const campaignDict = groupBy(this.eventCampaigns(), "authoringKey.id");

    let latestCampaigns: TournamentEventCampaign[] = [];

    for (let campGroup in campaignDict) {
      let camps = campaignDict[campGroup];
      camps.sort((a, b) => { return b.authoringKey.version - a.authoringKey.version });

      latestCampaigns.push(camps[0]);
    }

    return latestCampaigns;

  });

  private _campaignReviews = signal<CampaignReview[]>([]);
  campaignReviews = this._campaignReviews.asReadonly();

  //Includes all reviews since and including the last published review
  campaignReviewsSinceLastPublish: Signal<CampaignReview[]> = computed(() => {

    let campaignReviews = this._campaignReviews()
    campaignReviews.sort((a, b) => {
      return b.requestDate.getMilliseconds() - a.requestDate.getMilliseconds();
    });

    const latestReviews = [];

    let lastPublished = false;
    for (let campaign of campaignReviews) {
      if (!lastPublished) {
        latestReviews.push(campaign);
      }

      if (campaign.campaignReviewStatus == CampaignReviewStatus.Published) {
        lastPublished = true;
      }
    }

    return latestReviews;
  });

  // ///Sorted by
  sortedCampaignReviews: Signal<CampaignReview[]> = computed(() => {

    let campaignReviews = this._campaignReviews();

    campaignReviews.sort((a, b) => { return b.requestDate.getMilliseconds() - a.requestDate.getMilliseconds() });

    return campaignReviews;
  });

  private _events = signal<TournamentEvent[]>([]);
  events = this._events.asReadonly();

  latestEvents: Signal<TournamentEvent[]> = computed(() => {

    const eventsDict = groupBy(this._events(), "authoringKey.id");

    let latestEvents: TournamentEvent[] = [];

    for (let eventsGroup in eventsDict) {
      let events = eventsDict[eventsGroup];
      events.sort((a, b) => { return b.authoringKey.version - a.authoringKey.version });

      latestEvents.push(events[0]);
    }

    return latestEvents;

  });


  eventLocations: Signal<TournamentEventLocation[]> = computed(() => {

    var campaignEventLocations = [];

    const locations = this._events().map(e => e.location);

    const uniqueLocations = [];
    const uniqueLocationIds = [];

    for (let location of locations) {
      if (!uniqueLocationIds.includes(location.uid)) {
        uniqueLocations.push(location);
        uniqueLocationIds.push(location.uid);
      }
    }

    return uniqueLocations;
  })

  private _tournaments = signal<Tournament[]>([]);
  tournaments = this._tournaments.asReadonly();

  latestTournaments: Signal<Tournament[]> = computed(() => {

    const tournamentsDict = groupBy(this._tournaments(), "authoringKey.id");

    let latestTournaments: Tournament[] = [];

    for (let tournamentsGroup in tournamentsDict) {
      let tournaments = tournamentsDict[tournamentsGroup];
      tournaments.sort((a, b) => { return b.authoringKey.version - a.authoringKey.version });

      latestTournaments.push(tournaments[0]);
    }

    return latestTournaments;
  });

  public async focusOrganisation(organisationId: string): Promise<void> {

    var promise = new Promise<void>((resolve, reject) => {

      authState(this.afAuth)
        .subscribe(async (user: User) => {
          if (user) {
            const orgClaimsCallable = httpsCallable(this.functions, "setOrganisationClaims");
            console.log(this.functions);

            const result = await orgClaimsCallable({ organisationId: organisationId, email: user.email });

            if (result.data == "Ok") {
              user.getIdToken(true);
              this.initializeOrganisation(organisationId, user)
              resolve();
            }
          }
        });
    });

    return promise;

  }

  public initializeOrganisations() {
    const orgsQuery = query(collection(this.afs, "organisations"));//Orders by the numerical doc id and gets top 1

    const orgsSnapshot = getDocs(orgsQuery);

    orgsSnapshot.then(o => {

      const organisations = o.docs
        .map(m => {

          let orgData = m.data();

          return Organisation.Hydrate(
            m.id,
            orgData['name'],
            orgData['postcode'],
            orgData['logoPath'],
            orgData['administratorEmails'],
          );
        });

      this._organisations.set(organisations)
    });

  }

  private initializeOrganisation(organisationId: string, user: any) {

    if (organisationId) {

      const orgsQuery = query(collection(this.afs, "organisations"), and(where(documentId(), "==", organisationId)));//Orders by the numerical doc id and gets top 1

      const orgsSnapshot = getDocs(orgsQuery);

      orgsSnapshot.then(o => {

        const organisations = o.docs
          .map(m => {

            let orgData = m.data();

            return {
              uid: m.id,
              name: orgData['name'],
              postcode: orgData['postcode'],
              logoPath: orgData['logoPath'],
              administratorEmails: orgData['administratorEmails']
            } as Organisation;
          });

        this._organisation.set(organisations[0])
      });

      //Init campaigns
      this.initEventCampaigns(organisationId);

      //Init events
      this.initEvents(organisationId);

      // //Init tournaments
      this.initTournaments(organisationId);

      // //Init campaign reviews
      this.initCampaignReviews(organisationId);
    }
  }


  private initEventCampaigns(organisationId: string) {
    const eventsCollection = collection(this.afs, "event-campaigns");
    const eventsQuery = query(eventsCollection, where("organisationId", "==", organisationId));

    collectionData(eventsQuery, { idField: 'uid' })
      .pipe(
        //tap((data) => console.log(data)),
        map(ev => ev.map(m => {
          let eventData = m;

          const hydratedAuthoringKey = AuthoringKey.Hydrate(eventData['authoringKey']['id'], eventData['authoringKey']['version'] ?? 1);

          return TournamentEventCampaign.Hydrate(
            m['uid'],
            hydratedAuthoringKey,
            eventData['startDate'].toDate(),
            eventData['endDate'].toDate(),
            eventData['status'],
            eventData['organisationId'],
            eventData['posterPath'],
            eventData['contactEmail'],
            eventData['contactPhoneNumber'],
            eventData['entryWebsite'],
            +eventData['seasonAgeGroup']['startYear'],
            +eventData['seasonAgeGroup']['endYear'],
          );
        }))
      )
      .subscribe(c => {
        this._eventCampaigns.set(c);

        this.latestEventCampaigns = computed(() => {

          const campaignDict = groupBy(this.eventCampaigns(), "authoringKey.id");

          let latestCampaigns: TournamentEventCampaign[] = [];

          for (let campGroup in campaignDict) {
            let camps = campaignDict[campGroup];
            camps.sort((a, b) => { return b.authoringKey.version - a.authoringKey.version });

            latestCampaigns.push(camps[0]);
          }

          return latestCampaigns;

        });

      });
  }

  private initCampaignReviews(organisationId: string) {

    const campaignReviewsCollection = collection(this.afs, "event-campaign-reviews");
    const campaignReviewsQuery = query(campaignReviewsCollection, and(where("organisationId", "==", organisationId)));

    collectionData(campaignReviewsQuery, { idField: 'uid' })
      .pipe(
        //tap((data) => console.log(data)),
        map(ev => ev.map(m => {
          let eventData = m;

          return CampaignReview.Hydrate(
            m['uid'],
            eventData['requestDate'].toDate(),
            eventData['reviewDate']?.toDate(),
            eventData['requestDate'],
            ReviewEntity.Hydrate(eventData['typeName'], AuthoringKey.Hydrate(eventData['campaign']['authoringKey']['id'], eventData['campaign']['authoringKey']['version']), eventData['campaign']['correctiveActions'], eventData['campaign']['statusAtReview'], []), //Campaign
            eventData['events'].map(m =>
              ReviewEntity.Hydrate(
                m['typeName'],
                AuthoringKey.Hydrate(m['authoringKey']['id'], m['authoringKey']['version']),
                m['correctiveActions'],
                m['statusAtReview'],
                m['childEntities'].map(ce =>
                  ReviewEntity.Hydrate(
                    ce['typeName'],
                    AuthoringKey.Hydrate(ce['authoringKey']['id'],
                      ce['authoringKey']['version']),
                    ce['correctiveActions'],
                    ce['statusAtReview'],
                    [])
                )
              )
            ),
            eventData['organisationId'],
            eventData['campaignReviewStatus'] as CampaignReviewStatus,
          );
        })
        )
      )
      .subscribe(e => {
        this._campaignReviews.set(e);
      });
  }

  private initEvents(organisationId: string) {

    const eventsCollection = collection(this.afs, "events");
    const eventsQuery = query(eventsCollection, and(where("organisationId", "==", organisationId)));

    collectionData(eventsQuery, { idField: 'uid' })
      .pipe(
        //tap((data) => console.log(data)),
        map(ev => ev.map(m => {
          let eventData = m;
          return TournamentEvent.Hydrate(
            m['uid'],
            AuthoringKey.Hydrate(eventData['authoringKey']['id'], eventData['authoringKey']['version']),
            eventData['date'].toDate(),
            { hours: eventData['startTime'].hour, minutes: eventData['startTime'].minute },
            TournamentEventLocation.Hydrate(
              eventData['location']['uid'],
              eventData['location']['name'],
              { latitude: eventData['location']['location'].latitude, longitude: eventData['location']['location'].longitude } as GeolocationCoordinates),
            eventData['status'],
            eventData['organisationId'],
            eventData['eventCampaignId']);
        })
        )
      )
      .subscribe(e => {
        this._events.set(e);
      });
  }

  private initTournaments(organisationId: string) {
    const tournamentsCollection = collection(this.afs, "tournaments");
    const tournamentsQuery = query(tournamentsCollection, and(where("organisationId", "==", organisationId)));

    collectionData(tournamentsQuery, { idField: 'uid' })
      .pipe(
        //tap((data) => console.log(data)),
        map(ev => ev.map(m => {
          let eventData = m;
          return Tournament.Hydrate(
            m['uid'],
            AuthoringKey.Hydrate(eventData['authoringKey']['id'], eventData['authoringKey']['version']),
            eventData['eventId'],
            eventData['eligableGenders'],
            eventData['gameFormat'],
            eventData['competitiveness'],
            eventData['eligableAge'],
            eventData['pricePerTeamEntry'],
            eventData['status'],
            eventData['organisationId'],
          );
        })
        )
      )
      .subscribe(e => {
        this._tournaments.set(e);
      });
  }


  async CreateOrganisation(name: string, tempLogoPath: string, postcode: string): Promise<void> {

    const loggedInUser = this.authService.loggedInUser();

    const orgsReference = collection(this.afs, "organisations");
    const orgsQuery = query(orgsReference, and(where('name', "==", name)));//Orders by the numerical doc id and gets top 1

    const orgsSnapshot = await getDocs(orgsQuery);

    if (orgsSnapshot.docs.length > 0)
      throw new Error("Duplicate Organisation");

    const orgToSave = Organisation.Create(name, postcode, [loggedInUser.email]);

    var saveLogoPromise = new Promise<string | null>((resolve, reject) => {

      if (tempLogoPath) {
        //copy to new location
        const storage = getStorage();

        const tempLogoRef = ref(storage, tempLogoPath);

        const tempLogoPathParts = tempLogoPath.split("/");

        const organisationLogoPath = 'images/organisation-logos/' + orgToSave.uid + '/' + (tempLogoPathParts[tempLogoPathParts.length - 1]);
        const logoRef = ref(storage, organisationLogoPath);

        getBlob(tempLogoRef)
          .then((b) => {
            uploadBytes(logoRef, b)
              .catch((error) => {
                console.error('Upload organisation logo error');
                console.error(error);
              });

            //Need to delete the templogo folder, not just the file
            const tempLogoFolderPath = tempLogoPathParts.slice(0, tempLogoPathParts.length - 1).join('/');
            deleteObject(ref(storage, tempLogoFolderPath));

            resolve(organisationLogoPath);

          }).catch((error) => {
            console.error('Download organisation temp logo error');
            console.error(error);
          });
      }
      else {
        resolve(null);
      }
    });

    saveLogoPromise.then(path => {

      if (path) {
        orgToSave.SetLogoPath(path);
      }

      setDoc(doc(this.afs, 'organisations', nanoid()), orgToSave.ToPoco());//.then(d => {
      // localStorage.setItem('organisation', JSON.stringify({
      //   uid: orgToSave.uid,
      //   name: name
      // }));
      //});

    });
  }

  async EditOrganisation(name: string, postcode: string): Promise<void> {

    var promise = new Promise<void>((resolve, reject) => {

      authState(this.afAuth)
        .subscribe(async (user: User) => {
          if (user) {
            user.getIdTokenResult().then(async token => {
              let orgClaim = token.claims['organisationId'] as string;

              const orgsReference = collection(this.afs, "organisations");
              const orgsQuery = query(orgsReference, and(where(documentId(), "==", orgClaim), where("administratorEmails", 'array-contains', user.email)));//Orders by the numerical doc id and gets top 1


              let organisationsPromise = getDocs(orgsQuery);

              return organisationsPromise.then(async e => {

                let orgDoc = e.docs[0];
                let orgData = e.docs[0].data();

                const organisation = Organisation.Hydrate(
                  orgDoc.id,
                  orgData['name'],
                  orgData['postcode'],
                  orgData['logoPath'],
                  orgData['administratorEmails'],
                );

                organisation.SetName(name);
                organisation.SetPostcode(postcode);

                let organisationDoc = doc(this.afs, "organisations", orgDoc.id);

                updateDoc(organisationDoc, {
                  name: organisation.name,
                  postcode: organisation.postcode
                }).then(() => {
                  this.initializeOrganisation(orgDoc.id, user);
                  resolve();
                });
              });
            });
          }
        });
    });

    return promise;
  }

  async UploadPoster(campaignUid: string, fileRef: string): Promise<any> {
    console.log("Upload Poster");
    const eventsQuery = query(collection(this.afs, "event-campaigns"), where(documentId(), "==", campaignUid));

    let eventsPromise = getDocs(eventsQuery);

    return eventsPromise.then(async e => {

      let eventDoc = doc(this.afs, "event-campaigns", e.docs[0].id);

      return updateDoc(eventDoc, {
        posterPath: fileRef
      });
    })
  }


  async UploadOrganisationLogo(organisationId: string, fileRef: string): Promise<any> {
    console.log("Upload Logo");
    const organisationsQuery = query(collection(this.afs, "organisations"), where(documentId(), "==", organisationId));

    let organisationsPromise = getDocs(organisationsQuery);

    return organisationsPromise.then(async e => {

      let organisationDoc = doc(this.afs, "organisations", e.docs[0].id);

      return updateDoc(organisationDoc, {
        logoPath: fileRef
      });
    })
  }

  async UpdateEvent(eventUid: string, date: Date, time: Time, location: GeolocationCoordinates) {
    //If the Event is in a Draft state then its updated, else a new version is created
  }

  async CreateEvent(date: Date, time: Time, location: TournamentEventLocation, forCampaignId: string): Promise<string> {

    const promise = new Promise<string>(async (resolve, reject) => {

      // const loggedInUser = this.authService.loggedInUser();

      // if (!this.organisation().administratorEmails.includes(loggedInUser.email)) {
      //   throw new Error("User is not authorised to create Events");
      // }

      const eventsReference = collection(this.afs, "events");
      const eventsQuery = query(eventsReference, and(where('date', "==", date), where('time', '==', time)));//Orders by the numerical doc id and gets top 1

      const eventsSnapshot = await getDocs(eventsQuery);

      if (eventsSnapshot.docs.length > 0)
        throw new Error("Duplicate Event");

      let eventToSave = TournamentEvent.Create(date, time, location, this.organisation().uid, forCampaignId);

      await setDoc(doc(this.afs, 'events', eventToSave.uid), eventToSave.ToPoco());

      return resolve(eventToSave.uid);
    });

    return promise;
  }

  async EditEvent(eventId: string, date: Date, time: Time, location: TournamentEventLocation): Promise<void> {

    var promise = new Promise<void>((resolve, reject) => {

      authState(this.afAuth)
        .subscribe(async (user: User) => {
          if (user) {
            user.getIdTokenResult().then(async token => {
              let orgClaim = token.claims['organisationId'] as string;

              let latestEvent = this.latestEvents().filter(e => e.authoringKey.id == eventId)[0];

              const eventsReference = collection(this.afs, "events");
              const eventsQuery = query(eventsReference, and(where(documentId(), "==", latestEvent.uid), where("organisationId", '==', orgClaim)));//Orders by the numerical doc id and gets top 1

              let organisationsPromise = getDocs(eventsQuery);

              return organisationsPromise.then(async e => {

                let eventDoc = e.docs[0];
                let eventData = e.docs[0].data();

                //date, time, location, this.organisation().uid, forCampaignId

                const event = TournamentEvent.Hydrate(
                  eventDoc.id,
                  AuthoringKey.Hydrate(eventData['authoringKey']['id'], eventData['authoringKey']['version']),
                  eventData['date'].toDate(),
                  { hours: eventData['startTime'].hour, minutes: eventData['startTime'].minute },
                  TournamentEventLocation.Hydrate(
                    eventData['location']['uid'],
                    eventData['location']['name'],
                    { latitude: eventData['location']['location'].latitude, longitude: eventData['location']['location'].longitude } as GeolocationCoordinates),
                  eventData['status'],
                  eventData['organisationId'],
                  eventData['eventCampaignId']);

                event.Update(date, time, location);

                let eventDocRef = doc(this.afs, "events", eventDoc.id);

                updateDoc(eventDocRef, {
                  date: Timestamp.fromDate(event.date),
                  startTime: event.startTime,
                  location: event.location.ToPoco()
                }).then(() => {
                  this.initEvents(orgClaim);
                  resolve();
                });
              });
            });
          }
        });
    });

    return promise;

  }

  async CreateTournament(eligableGenders: string, eligableAge: Age, gameFormat: string, competitiveness: string, pricePerTeamEntry: number, forEventId: string): Promise<string> {

    const promise = new Promise<string>(async (resolve, reject) => {

      // const loggedInUser = this.authService.loggedInUser();

      // if (!this.organisation().administratorEmails.includes(loggedInUser.email)) {
      //   throw new Error("User is not authorised to create Events");
      // }

      await this.authService.userClaims.subscribe(async claims => {

        const organisationId = claims['organisationId'] as string;

        if (!organisationId)
          throw Error("User is not focused on an Organisation")
        //const eventsQuery = query(eventsReference, and(where('date', "==", date), where('time', '==', time)));//Orders by the numerical doc id and gets top 1

        //const eventsSnapshot = await getDocs(tournamentsReference);

        // if (eventsSnapshot.docs.length > 0)
        //   throw new Error("Duplicate Event");
        //const tournamentsReference = collection(this.afs, "tournaments");

        let tournamentToSave = Tournament.Create(eligableGenders, gameFormat, competitiveness, eligableAge, pricePerTeamEntry, forEventId, organisationId);

        await setDoc(doc(this.afs, 'tournaments', tournamentToSave.uid), tournamentToSave.ToPoco());

        return resolve(tournamentToSave.uid);
      });
    });

    return promise;

  }

  async EditTournament(tournamentId: string, eligableGenders: string, eligableAge: Age, gameFormat: string, competitiveness: string, pricePerTeamEntry: number): Promise<void> {

    const promise = new Promise<void>(async (resolve, reject) => {

      authState(this.afAuth)
        .subscribe(async (user: User) => {
          if (user) {
            user.getIdTokenResult().then(async token => {
              let orgClaim = token.claims['organisationId'] as string;

              let latestTournament = this.latestTournaments().filter(t => t.authoringKey.id == tournamentId)[0];

              const tournamentsReference = collection(this.afs, "tournaments");
              const tournamentsQuery = query(tournamentsReference, and(where(documentId(), "==", latestTournament.uid), where("organisationId", '==', orgClaim)));//Orders by the numerical doc id and gets top 1

              let tournamentsPromise = getDocs(tournamentsQuery);

              return tournamentsPromise.then(async e => {

                let tournamentDoc = e.docs[0];
                let tournamentData = e.docs[0].data();

                const hydratedTournament = Tournament.Hydrate(
                  tournamentDoc.id,
                  AuthoringKey.Hydrate(tournamentData['authoringKey']['id'], tournamentData['authoringKey']['version']),
                  tournamentData['eventId'],
                  tournamentData['eligableGenders'],
                  tournamentData['gameFormat'],
                  tournamentData['competitiveness'],
                  tournamentData['eligableAge'],
                  tournamentData['pricePerTeamEntry'],
                  tournamentData['status'],
                  tournamentData['organisationId']);

                hydratedTournament.Update(eligableGenders, gameFormat, competitiveness, eligableAge, pricePerTeamEntry);

                let tournamentDocRef = doc(this.afs, "tournaments", tournamentDoc.id);

                updateDoc(tournamentDocRef, {
                  eligableGenders: hydratedTournament.eligableGenders,
                  gameFormat: hydratedTournament.gameFormat,
                  competitiveness: hydratedTournament.competitiveness,
                  eligableAge: hydratedTournament.eligableAge,
                  pricePerTeamEntry: hydratedTournament.pricePerTeamEntry
                }).then(() => {
                  this.initTournaments(orgClaim);
                  resolve();
                });
              });
            });
          }
        });
    });

    return promise;

  }

  async CreateEventCampaign(startDate: Date, endDate: Date, contactEmail: string, contactPhoneNumber: string, entryWebsite: string, seasonAgeGroupStartYear: number, seasonAgeGroupEndYear: number): Promise<string> {

    const userLoggedIn = this.authService.isAuthenticated;

    if (!userLoggedIn)
      throw Error("User is not authenticated")

    const promise = new Promise<string>((resolve, reject) => {

      this.authService.userClaims.subscribe(async claims => {

        const organisationId = claims['organisationId'] as string;

        if (!organisationId)
          throw Error("User is not focused on an Organisation")

        // const loggedInUser = this.authService.loggedInUser();

        // if (!this.organisation().administratorEmails.includes(loggedInUser.email)) {
        //   throw new Error("User is not authorised to create Events");
        // }

        let eventCampaignToSave = TournamentEventCampaign.Create(startDate, endDate, contactEmail, contactPhoneNumber, entryWebsite, seasonAgeGroupStartYear, seasonAgeGroupEndYear, organisationId);

        await setDoc(doc(this.afs, 'event-campaigns', eventCampaignToSave.uid), eventCampaignToSave.ToPoco());

        return resolve(eventCampaignToSave.authoringKey.id);
      });
    });

    return promise;

  }


  async EditEventCampaign(campaignId: string, startDate: Date, endDate: Date, contactEmail: string, contactPhoneNumber: string, entryWebsite: string, seasonAgeGroupStartYear: number, seasonAgeGroupEndYear: number): Promise<void> {

    const promise = new Promise<void>((resolve, reject) => {

      authState(this.afAuth)
        .subscribe(async (user: User) => {
          if (user) {
            user.getIdTokenResult().then(async token => {
              let organisationId = token.claims['organisationId'] as string;


              if (!organisationId)
                throw Error("User is not focused on an Organisation")

              // const loggedInUser = this.authService.loggedInUser();

              // if (!this.organisation().administratorEmails.includes(loggedInUser.email)) {
              //   throw new Error("User is not authorised to create Events");
              // }

              const latestCampaign = this.latestEventCampaigns().filter(c => c.authoringKey.id == campaignId)[0];

              const campaignsReference = collection(this.afs, "event-campaigns");
              const campaignsQuery = query(campaignsReference, and(where(documentId(), "==", latestCampaign.uid), where("organisationId", '==', organisationId)));//Orders by the numerical doc id and gets top 1

              let campaignsPromise = getDocs(campaignsQuery);

              return campaignsPromise.then(async e => {

                let campaignDoc = e.docs[0];
                let campaignData = e.docs[0].data();

                const campaignAuthoringKey = AuthoringKey.Hydrate(campaignData['authoringKey']['id'], campaignData['authoringKey']['version']);

                const hydratedCampaign = TournamentEventCampaign.Hydrate(
                  campaignDoc.id,
                  campaignAuthoringKey,
                  campaignData['startDate'].toDate(),
                  campaignData['endDate'].toDate(),
                  campaignData['status'],
                  campaignData['organisationId'],
                  campaignData['posterPath'],
                  campaignData['contactEmail'],
                  campaignData['contactPhoneNumber'] ?? null,
                  campaignData['entryWebsite'] ?? null,
                  +campaignData['seasonAgeGroup']['startYear'],
                  +campaignData['seasonAgeGroup']['endYear'],);

                hydratedCampaign.Update(startDate, endDate, contactEmail, contactPhoneNumber, entryWebsite, seasonAgeGroupStartYear, seasonAgeGroupEndYear);

                let campaignDocRef = doc(this.afs, "event-campaigns", campaignDoc.id);

                const campaignPoco = hydratedCampaign.ToPoco();

                updateDoc(campaignDocRef, {
                  startDate: campaignPoco.startDate,
                  endDate: campaignPoco.endDate,
                  contactEmail: campaignPoco.contactEmail,
                  contactPhoneNumber: campaignPoco.contactPhoneNumber,
                  entryWebsite: campaignPoco.entryWebsite,
                  seasonAgeGroup: campaignPoco.seasonAgeGroup
                }).then(() => {
                  this.initEventCampaigns(organisationId);
                  resolve();
                });
              })
            });
          }
        });
    });

    return promise;

  }



  async SubmitForReview(campaignUid: string) {

    //Get the event campaign
    const campaign = this.latestEventCampaigns().filter(c => c.authoringKey.id == campaignUid)[0];
    const events = this.latestEvents().filter(c => c.eventCampaignId == campaignUid);
    const tournaments = this.latestTournaments();

    //Get the latest review
    const review = this.campaignReviewsSinceLastPublish().filter(r => r.campaign.authoringKey.id == campaignUid)[0];

    //CONSIDER: Adding an 'UNDER REVIEW' status which prevents users from submitting a new review while the present one is being reviewed.

    let campaignEntityReview = ReviewEntity.Create('Campaign', campaign.authoringKey, campaign.status, []);
    let eventEntityReviews = []

    for (let event of events) {

      const tournamentReviews = tournaments
        .filter(t => t.eventId == event.authoringKey.id)
        .map(t => ReviewEntity.Create("Tournament", t.authoringKey, t.status, []));

      eventEntityReviews.push(ReviewEntity.Create("Event", event.authoringKey, event.status, tournamentReviews))
    }


    //If no ACTIVE reviews for this campaign then create a new one.
    if (!review) {
      const newReview = CampaignReview.Create(campaignEntityReview, eventEntityReviews, campaign.organisationId);

      await setDoc(doc(this.afs, 'event-campaign-reviews', newReview.uid), newReview.ToPoco());
    }
    else {
      //If there is an existing pending review then the entities should be updated on the existing review.
      review.Update(campaignEntityReview, eventEntityReviews);

      await setDoc(doc(this.afs, 'event-campaign-reviews', review.uid), review.ToPoco());
    }
  }

  // UserOrganisations(): Observable<Organisation[]> {

  //   const loggedInUser = this.authService.loggedInUser();

  //   const orgsQuery = query(collection(this.afs, "organisations"), where("administratorEmails", 'array-contains', loggedInUser.email));//Orders by the numerical doc id and gets top 1

  //   const organisations$ = collectionData(orgsQuery, { idField: 'uid' })
  //     .pipe(
  //       //tap((data) => console.log(data)),
  //       map(ev => ev.map(m => {
  //         return {
  //           uid: m['uid'],
  //           name: m['name'],
  //           administratorEmails: m['administratorEmails']
  //         } as Organisation;
  //       })
  //       )
  //     )

  //   return organisations$;
  // }

  //Assesses the campaign and its child entities () are valid 
  GetCampaignReview(campaignId: string): EntityReview {
    const campaign = this.latestEventCampaigns().filter(c => c.authoringKey.id == campaignId)[0];
    return this.AssessCampaign(campaign);
  }

  private AssessCampaign(campaign: TournamentEventCampaign): EntityReview {

    var events = this.latestEvents().filter(e => e.eventCampaignId == campaign.authoringKey.id);

    var eventReviews: EntityReview[] = []

    for (let e of events) {
      eventReviews.push(this.AssessEvent(e));
    }

    let issues: string[] = [];
    if (events.length == 0) {
      issues.push("No events have been added to this campaign.")
    }

    return EntityReview.Create("Campaign", campaign.authoringKey, campaign.status, issues, eventReviews);

  }

  private AssessEvent(event: TournamentEvent): EntityReview {

    var tournaments = this.latestTournaments().filter(t => t.eventId == event.authoringKey.id);

    var tournamentReviews: EntityReview[] = []

    for (let t of tournaments) {
      tournamentReviews.push(this.AssessTournament(t));
    }

    let issues: string[] = [];
    if (tournaments.length == 0) {
      issues.push("No tournaments have been added to this campaign.")
    }

    return EntityReview.Create("Event", event.authoringKey, event.status, issues, tournamentReviews);
  }

  private AssessTournament(tournament: Tournament): EntityReview {

    //var tournaments = this.latestTournaments().filter(t => t.eventId  == event.eventId);

    //var eventReviews = 

    let issues: string[] = [];

    return EntityReview.Create("Tournament", tournament.authoringKey, tournament.status, issues, []);
  }

}
