import { GeoPoint } from "@angular/fire/firestore";
import { nanoid } from "nanoid";

export class TournamentEventLocation {
    uid: string;
    name: string;
    location: GeolocationCoordinates;

    constructor(uid: string, name: string, location: GeolocationCoordinates) { 
        this.uid = uid;
        this.name = name;
        this.location = location;
    }

    public static Create(name: string, location: GeolocationCoordinates) {
        return new TournamentEventLocation(nanoid(), name, location);
    }

    public static Hydrate(uid: string, name: string, location: GeolocationCoordinates) {
        return new TournamentEventLocation(uid, name, location);
    }

    public ToPoco():any{
        return {
            uid: this.uid,
            name: this.name,
            location:  new GeoPoint(this.location.latitude, this.location.longitude)
        };
    }

}