
<header class="header navbar-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="nav-inner">
                    <!-- Start Navbar -->
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="/">
                            <img src="assets/logos/grassroots-tournaments-logo-whiteblack.png" alt="Logo">
                        </a>
                        <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                            <ul id="nav" class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <a href="/" aria-label="Toggle navigation">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a href="" data-anchor="features" class="page-scroll"
                                        aria-label="Toggle navigation">Features</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a href="/search" aria-label="Toggle navigation">Search</a>
                                </li> -->
                                <!-- <li class="nav-item">
                                    <a href="/promote" aria-label="Toggle navigation">Promote</a>
                                </li> -->
                                <!-- <li class="nav-item">
                                    <a href="javascript:void(0)" aria-label="Toggle navigation">Overview</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#pricing" class="page-scroll" aria-label="Toggle navigation">Pricing</a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" aria-label="Toggle navigation">Team</a>
                                </li>
                                <li class="nav-item">
                                    <a class="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                                        data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">Blog</a>
                                    <ul class="sub-menu collapse" id="submenu-1-4">
                                        <li class="nav-item">
                                            <a href="javascript:void(0)">Blog Grid Sidebar</a>
                                        </li>
                                        <li class="nav-item"><a href="javascript:void(0)">Blog Single</a></li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0)">
                                                Blog Single
                                                Sibebar
                                            </a>
                                        </li>
                                    </ul>
                                </li> -->
                                <li class="nav-item">
                                    <a href="/contact" aria-label="Toggle navigation">Contact</a>
                                </li>
                            </ul>

                            <!-- <div class="collapse navbar-collapse" id="navbarResponsive"> -->
                            <ul class="navbar-nav auth-nav">
                                <!-- <li class="nav-item mx-0 mx-lg-1" *ngIf="!loggedInUser()">
                                    <a class="nav-link py-3 px-0 pe-lg-1 ps-lg-3 rounded d-inline" href="register">
                                          <fa-icon [icon]="faUserPlus"></fa-icon> Register
                                        </a>
                                    <a class="nav-link py-3 px-0 ps-lg-1 pe-lg-3 rounded d-inline" href="sign-in">
                                        <fa-icon [icon]="faArrowRightToBracket"></fa-icon> Sign In
                                    </a>
                                </li> -->
                                <!-- <li class="nav-item mx-0 mx-lg-1" *ngIf="loggedInUser() as user">
                                    <a class="btn btn-primary me-2 float-start " href="/dashboard">
                                        <fa-icon [icon]="faUser"></fa-icon>&nbsp;Dashboard
                                    </a>
                                    <a (click)="signOut()" href="#" class="float-end ">
                                        <fa-icon [icon]="faRightFromBracket"></fa-icon> Sign Out
                                    </a>
                                </li> -->
                            </ul>
                            <!-- </div> -->
                        </div>
                        <!-- <div class="button add-list-button">
                            <a href="javascript:void(0)" class="btn">Get it now</a>
                        </div> -->
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- 
<div class="preloader">
    <div class="preloader-inner">
        <div class="preloader-icon">
            <span></span>
            <span></span>
        </div>
    </div>
</div> -->

<router-outlet></router-outlet>

<script src="/src/assets/js/interaction.js"></script>