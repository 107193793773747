import { nanoid } from "nanoid";
import { AuthoringKey } from "../authoring-key";
import { EntityReviewStatus } from "../enums/entityReviewStatus.enum";


export class ReviewEntity {

    constructor(typeName: string, authoringKey: AuthoringKey, correctiveActions: ReviewCorrectiveAction[], statusAtReview: EntityReviewStatus, childEntities: ReviewEntity[]) {
        this.typeName = typeName;
        this.authoringKey = authoringKey;
        this.correctiveActions = correctiveActions;
        this.statusAtReview = statusAtReview;
        this.childEntities = childEntities;
    }

    public typeName: string;

    public authoringKey: AuthoringKey;

    public correctiveActions: ReviewCorrectiveAction[];

    public statusAtReview: EntityReviewStatus;

    public childEntities: ReviewEntity[];

    public static Create(typeName: string, authoringKey: AuthoringKey, statusAtReview: EntityReviewStatus, childEntities: ReviewEntity[]) {
        return new ReviewEntity(typeName, authoringKey, [], statusAtReview, childEntities);
    }

    public static Hydrate(typeName: string, authoringKey: AuthoringKey, correctiveActions: ReviewCorrectiveAction[], statusAtReview: EntityReviewStatus, childEntities: ReviewEntity[]) {
        return new ReviewEntity(typeName, authoringKey, correctiveActions, statusAtReview, childEntities);
    }

    // public static FromJsonObject(typeName:string, authoringKey: any, correctiveActions: any[], statusAtReview: any, childEntities: any[]) {
    //     return new ReviewEntity(AuthoringKey.FromJsonObject(authoringKey), correctiveActions.map(a => ReviewCorrectiveAction.FromJsonObject(a)), statusAtReview);
    // }

    public ToPoco(): any {
        return {
            typeName: this.typeName,
            authoringKey: this.authoringKey.ToPoco(),
            correctiveActions: this.correctiveActions,
            statusAtReview: this.statusAtReview,
            childEntities: this.childEntities.map(ce => ce.ToPoco())
        };
    }

    public IssueCount(): number {

        var sumOfChildCorrectiveActions = 0;

        if (this.childEntities?.length > 0) {
            sumOfChildCorrectiveActions = this.childEntities.reduce((sum, num) => sum + num.IssueCount(), 0)
        }

        return this.correctiveActions.length + sumOfChildCorrectiveActions;
    }
}

export class ReviewCorrectiveAction {

    public id: string;
    public action: string;

    constructor(id: string, action: string) {
        this.id = id;
        this.action = action;
    }

    public static Create(action: string) {
        return new ReviewCorrectiveAction(nanoid(), action);
    }

    public static Hydrate(id: string, action: string) {
        return new ReviewCorrectiveAction(id, action);
    }

    public static FromJsonObject(correctiveActionObject: any) {
        return new ReviewCorrectiveAction(correctiveActionObject.id, correctiveActionObject.action);
    }

    public ToPoco(): any {
        return {
            id: this.id,
            action: this.action
        };
    }


}