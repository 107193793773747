import { withJsonpSupport } from '@angular/common/http';
import { TournamentEvent } from '../authoring/tournamentEvent.model';
import { Tournament } from '../authoring/tournament.model';
import TournamentEventCampaign from "../authoring/tournamentEventCampaign.model";
import { Timestamp } from "@angular/fire/firestore";
import { nanoid } from "nanoid";
import { ReviewEntity } from './review-entity.model';

export class CampaignReview {

    constructor(uid: string, requestDate: Date, reviewDate: Date | null, reviewedBy: string | null, campaign: ReviewEntity, events: ReviewEntity[], organisationId: string, campaignReviewStatus:CampaignReviewStatus) {
        this.uid = uid;
        this.requestDate = requestDate;
        this.reviewDate = reviewDate;
        this.reviewedBy = reviewedBy;
        this.campaign = campaign;
        this.events = events;
        this.organisationId = organisationId;
        this.campaignReviewStatus = campaignReviewStatus;
    }


    public uid: string;

    public requestDate: Date;

    public reviewDate: Date;

    public reviewedBy: string;

    public campaign: ReviewEntity;

    public events: ReviewEntity[];

    //public tournaments: ReviewEntity[];

    public campaignReviewStatus: CampaignReviewStatus;

    public organisationId: string;

    public static Create(campaign: ReviewEntity, events: ReviewEntity[], organisationId: string) {
        return new CampaignReview(
            nanoid(),
            new Date(),
            null,
            null,
            campaign,
            events,
            organisationId,
            CampaignReviewStatus.AwaitingReview
        );
    }

    public Update(campaign: ReviewEntity, events: ReviewEntity[]) {
        this.campaign = campaign;
        this.events = events;
    }

    public static Hydrate(reviewId: string, requestDate: Date, reviewDate: Date, reviewedBy: string, campaign: ReviewEntity, events: ReviewEntity[], organisationId: string, campaignReviewStatus: CampaignReviewStatus) {
        return new CampaignReview(
            reviewId,
            requestDate,
            reviewDate,
            reviewedBy,
            campaign,
            events,
            organisationId,
            campaignReviewStatus
        );
    }

    public ToPoco(): any {
        return {
            requestDate: Timestamp.fromDate(this.requestDate),
            reviewDate: this.reviewDate ? Timestamp.fromDate(this.reviewDate) : null,
            reviewedBy: this.reviewedBy,
            campaign: this.campaign.ToPoco(),
            events: this.events.map(e => e.ToPoco()),
            organisationId: this.organisationId,
            campaignReviewStatus: this.campaignReviewStatus
        };
    }

    // public static FromJsonObject(jsonObject: any): CampaignReview {

    //     //const jsonObject = JSON.parse(jsonString);

    //     return CampaignReview.Hydrate(
    //         jsonObject.uid,
    //         jsonObject.requestDate,
    //         jsonObject.reviewDate,
    //         jsonObject.reviewedBy,
    //         ReviewEntity.FromJsonObject(
    //             AuthoringKey.FromJsonObject(jsonObject.campaign.authoringKey),
    //             jsonObject.campaign.correctiveActions.map(a => ReviewCorrectiveAction.FromJsonObject(a)),
    //             jsonObject.campaign.statusAtReview
    //         ),
    //         jsonObject.events.map(e => ReviewEntity.FromJsonObject(
    //             AuthoringKey.FromJsonObject(e),
    //             e.correctiveActions.map(a => ReviewCorrectiveAction.FromJsonObject(a)),
    //             e.statusAtReview
    //         )),
    //         jsonObject.tournaments.map(e => ReviewEntity.FromJsonObject(
    //             AuthoringKey.FromJsonObject(e),
    //             e.correctiveActions.map(a => ReviewCorrectiveAction.FromJsonObject(a)),
    //             e.statusAtReview
    //         )),
    //         jsonObject.organisationId,
    //         CampaignReviewStatus[jsonObject.campaignReviewStatus]
    //     );
    // }


    public IssueCount(): number {

        let count = 0;

        count = this.campaign.IssueCount();

        this.events.forEach(element => {
            count += element.IssueCount();
        });

        return count;
    }


    public HasChanged(campaigns: TournamentEventCampaign[], events: TournamentEvent[], tournaments: Tournament[]) {

        const campaign = campaigns.filter(f => f.authoringKey.id == this.campaign.authoringKey.id)[0];

        if (campaign.authoringKey.version != this.campaign.authoringKey.version)
            return true;

        const campaignEvents = events.filter(e => e.eventCampaignId == this.campaign.authoringKey.id);

        if(this.events.length != campaignEvents.length)
            return true;

        for (let event of this.events) {
            const eventToCompare = campaignEvents.filter(e => e.authoringKey.id == event.authoringKey.id)[0];

            if (event.authoringKey.version != eventToCompare.authoringKey.version)
                return true;

            const eventTournamentReviewEntities = event.childEntities.filter(e => e.typeName = 'Tournament');

            const eventTournamentReviewEntityKeys = eventTournamentReviewEntities.map(m => m.authoringKey.id);
            const eventTournaments = tournaments.filter(e => eventTournamentReviewEntityKeys.includes(event.authoringKey.id));

            if(event.childEntities.length != eventTournaments.length)
                return true;
            
            for (let eventTournament of eventTournaments) {
                const tournamentToCompare = eventTournaments.filter(e => e.authoringKey.id == eventTournament.authoringKey.id)[0];

                if (eventTournament.authoringKey.version != tournamentToCompare.authoringKey.version)
                    return true;
            }
        }
        return false;
    }
}


export enum CampaignReviewStatus {
    Published = "Published",
    AwaitingReview = "Awaiting Review",
    Rejected = "Rejected",
    Superceeded = "Superceeded"
}