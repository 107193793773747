import { AuthoringKey } from '../authoring-key';
import { nanoid } from "nanoid";
import { EntityReviewStatus } from "../enums/entityReviewStatus.enum";
import { Age } from '../enums/age.enum';

export class Tournament {

    uid: string;
    authoringKey: AuthoringKey;
    eventId: string;
    eligableGenders: string;
    gameFormat: string;
    competitiveness: string;
    eligableAge: Age;
    pricePerTeamEntry: number;
    status: EntityReviewStatus;
    organisationId: string;

    private constructor(uid: string, authoringKey: AuthoringKey, eventId: string, eligableGenders: string, gameFormat: string, competitiveness: string, eligableAge: Age, pricePerTeamEntry: number, status: EntityReviewStatus, organisationId: string) {
        this.uid = uid;
        this.authoringKey = authoringKey;
        this.eventId = eventId;
        this.eligableGenders = eligableGenders;
        this.gameFormat = gameFormat;
        this.eligableAge = eligableAge;
        this.pricePerTeamEntry = pricePerTeamEntry;
        this.competitiveness = competitiveness;
        this.status = status;
        this.organisationId = organisationId;
    }

    public static Create(eligableGenders: string, gameFormat: string, competitiveness: string, eligableAge: Age, pricePerTeamEntry: number, eventId: string, organisationId: string): Tournament {
        return new Tournament(
            nanoid(),
            AuthoringKey.Create(),
            eventId,
            eligableGenders,
            gameFormat,
            competitiveness,
            eligableAge,
            pricePerTeamEntry,
            EntityReviewStatus.Draft,
            organisationId
        );
    }

    public Update(eligableGenders: string, gameFormat: string, competitiveness: string, eligableAge: Age, pricePerTeamEntry: number): void {
        this.eligableGenders = eligableGenders;
        this.gameFormat = gameFormat;
        this.competitiveness = competitiveness;
        this.eligableAge = eligableAge;
        this.pricePerTeamEntry = pricePerTeamEntry;
    }

    public static Hydrate(uid: string, authoringKey: AuthoringKey, eventId: string, eligableGenders: string, gameFormat: string, competitiveness: string, eligableAge: Age, pricePerTeamEntry: number, status: EntityReviewStatus, organisationId: string): Tournament {
        return new Tournament(
            uid,
            authoringKey,
            eventId,
            eligableGenders,
            gameFormat,
            competitiveness,
            eligableAge,
            pricePerTeamEntry,
            status,
            organisationId
        );
    }

    public ToPoco(): any {
        return {
            authoringKey: this.authoringKey.ToPoco(),
            eventId: this.eventId,
            gameFormat: this.gameFormat,
            eligableGenders: this.eligableGenders,
            competitiveness: this.competitiveness,
            eligableAge: this.eligableAge,
            pricePerTeamEntry: this.pricePerTeamEntry,
            status: this.status,
            organisationId: this.organisationId
        }
    }
}
