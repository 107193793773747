export const environment = {
    production: true,
    useEmulators: false,
    firebase: {
        apiKey: "AIzaSyDIhxo9drGJ-7r_4IVkCj0hj_oRP44cavE",
        authDomain: "solid-scheme-383010.firebaseapp.com",
        projectId: "solid-scheme-383010",
        storageBucket: "grts-website-dev",
        messagingSenderId: "44373413829",
        appId: "1:44373413829:web:768e56d1870e72cbfc05ac",
        measurementId: "G-P9KVCT6QEL",
        mapsApiKey: "AIzaSyD-41BGujGvLBq8AKx-nVqpZB5AKUVA0rU",
        databaseName: "website",
        region: "europe-west2"       
    },
    reCAPTCHASiteKey:"6LeFUvspAAAAAFP3oOs3dmbWXoJ5kciLRGv6Xk1i",
    appCheckDebugToken: false
};
