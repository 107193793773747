import { Time } from "@angular/common";
import { GeoPoint, Timestamp } from "@angular/fire/firestore";
import { nanoid } from "nanoid";
import { EntityReviewStatus } from "../enums/entityReviewStatus.enum";
import { AuthoringKey } from "../authoring-key";
import { TournamentEventLocation } from "./tournamentEventLocation.model";

export class TournamentEvent {
    uid: string;
    authoringKey:AuthoringKey;
    campaignId: string;
    date: Date;
    startTime: Time;
    location: TournamentEventLocation;
    status: EntityReviewStatus;
    organisationId: string;
    eventCampaignId: string;

    private constructor(uid: string, authoringKey: AuthoringKey, date: Date, startTime: Time, location: TournamentEventLocation, status: EntityReviewStatus, organisationId: string, campaignId: string) {
        this.uid = uid;
        this.authoringKey = authoringKey;
        this.date = date;
        this.startTime = startTime;
        this.location = location;
        this.status = status;
        this.organisationId = organisationId;
        this.eventCampaignId = campaignId;
    }

    public static Create(date: Date, startTime: Time, location: TournamentEventLocation, organisationId: string, campaignId: string): TournamentEvent {
        return new TournamentEvent(nanoid(), AuthoringKey.Create(), date, startTime, location, EntityReviewStatus.Draft, organisationId, campaignId);
    }

    public Update(date: Date, startTime: Time, location: TournamentEventLocation): void {
        this.date = date;
        this.startTime = startTime;
        this.location = location;
    }

    public static Hydrate(uid: string, authoringKey:AuthoringKey, date: Date, startTime: Time, location: TournamentEventLocation, status: EntityReviewStatus, organisationId: string, campaignId: string): TournamentEvent {
        return new TournamentEvent(uid, authoringKey, date, startTime, location, status, organisationId, campaignId);
    }

    public ToPoco(): any {
        return {
            authoringKey: this.authoringKey.ToPoco(),
            date: Timestamp.fromDate(this.date),
            startTime: this.startTime,
            location: this.location.ToPoco(),
            status: this.status,
            organisationId: this.organisationId,
            eventCampaignId: this.eventCampaignId
        };
    }
}