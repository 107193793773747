import { AuthoringKey } from './authoring-key';
import { EntityReviewStatus } from "./enums/entityReviewStatus.enum";

export class EntityReview {

    entityTypeName: string;
    authoringKey:AuthoringKey;
    entityReviewStatus: EntityReviewStatus;
    issues: string[];

    childEntities: EntityReview[];

    HasIssues(): boolean {
        return this.issues && this.issues.length > 0 || (this.childEntities.filter(ce => ce.HasIssues()).length > 0);
    }

    constructor(entityTypeName: string, authoringKey:AuthoringKey, entityReviewStatus: EntityReviewStatus, issues: string[], childEntities: EntityReview[]) {
        this.entityTypeName = entityTypeName;
        this.authoringKey = authoringKey;
        this.entityReviewStatus = entityReviewStatus;
        this.issues = issues;

        this.childEntities = childEntities;
    }

    public static Create(entityTypeName: string, authoringKey:AuthoringKey, entityReviewStatus: EntityReviewStatus, issues: string[], children: EntityReview[]) {
        return new EntityReview(entityTypeName, authoringKey, entityReviewStatus, issues, children);
    }

}